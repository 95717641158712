<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update' ? $lang.ga.crud.edit : $lang.ga.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary"
    >
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div class="form-group">
            <label class="form__label"
            >{{ $lang.ga.form.country }}
              <required_span/>
            </label>
            <v-select
                :options="CountryOptions"
                v-model="ga.country"
                @option:selected="loadState"
            ></v-select>
          </div>
          <div class="form-group">
            <label class="form__label"
            >{{ $lang.ga.form.state }}
              <required_span/>
            </label>
            <v-select :options="StateOptions" v-model="ga.state"></v-select>
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label"
            >{{ $lang.ga.form.industry }}
              <required_span/>
            </label>
            <v-select
                :options="industryOptions"
                v-model="ga.industry"
                @option:selected="getSubIndustry"
            >
            </v-select>
            <small
                class="error"
                v-if="$v.ga.industry.$error && !$v.ga.industry.required"
            >
              {{ $lang.ga.validation.required.industry }}
            </small>
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label"
            >{{ $lang.ga.form.subIndustry }}
              <required_span/>
            </label>
            <v-select
                multiple
                :options="subIndustryOptions"
                v-model="ga.subIndustry"
            ></v-select>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted"
          >
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CForm>
    </CModal>
    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <CCol class="row">
            <CCol col="10">
              {{ $lang.ga.title }}
            </CCol>
            <CCol col="1" class="mfs-auto">
              <CButton
                  shape="pill"
                  color="primary"
                  v-on:click="createTableRow"
                  v-if="checkPermission('create-state')"
              >
                {{ $lang.buttons.general.crud.create }}
              </CButton>
            </CCol>
            <CCol col="1"></CCol>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="/ga/list"
              :options="options"
              ref="myTable"
          >
            <template #actions="data"></template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {state, country, industry, gA} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import required_span from "../../components/layouts/general/required-span";

import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "giveAndAsk",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      StateOptions: [],
      CountryOptions: [],
      industryOptions: [],
      subIndustryOptions: [],
      self: this,
      module: gA,
      stateModule: state,
      countryModule: country,
      moduleIndustry: industry,
      ga: {
        country: "",
        state: "",
        industry: "",
        subIndustry: "",
      },
      columns: ["state", "industry", "title", "status"],
      data: [],
      options: {
        headings: {
          state: this.$lang.ga.table.state,
          industry: this.$lang.ga.table.industry,
          title: this.$lang.ga.table.group,
          status: this.$lang.ga.table.status,
        },
        editableColumns: ["countryName", "name"],
        sortable: ["countryName", "name"],
        filterable: ["countryName", "name"],
        // see the options API
      },
    };
  },
  validations: {
    ga: {
      state: {
        required,
      },
      country: {
        required,
      },
      industry: {
        required,
      },
    },
  },

  created() {
    store.commit("showLoader", false); // Loader Off
    if (
        this.checkPermission("edit-state") === true ||
        this.checkPermission("delete-state") === true
    ) {
      this.columns.push("actions");
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    axios.get(this.listUrlApi(this.countryModule)).then((response) => {
      response.data.data.map(function (value, key) {
        self.CountryOptions.push({value: value.id, label: value.name});
      });
    });
    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        if (value.industryId === "") {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    loadState() {
      let self = this;
      const postData = {countryId: self.ga.country};
      axios
          .post(this.filterUrApi(this.stateModule), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },

    getSubIndustry() {
      let self = this;
      if (self.ga.industry.value > 0) {
        const postData = {industry_id: self.ga.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.ga.isActive = 1;
      } else {
        self.ga.isActive = 0;
      }
    },

    applyFilter() {
      let self = this;
      let reqObj = {
        countryId: self.ga.countryName.value,
      };
      store.commit("showLoader", true); // Loader Off
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      store.commit("showLoader", true); // Loader Off
      this.ga.countryName = {};
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.ga.name = "";
      self.ga.countryId = "";
      self.largeModal = true;
      //this.$router.push({name: 'Country Create'})
    },

    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              let responseData = response.data;
              self.ga.name = responseData.name;
              self.ga.isActive = responseData.isActive;
              self.ga.countryName = {
                value: responseData.countryId,
                label: responseData.countryName,
              };
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                let responseData = response.data;
                if (responseData.code === 200) {
                  // self.$router.go()
                  self.$refs.myTable.refresh();
                  self.alertMessage = responseData.response.message;
                  self.dismissCountDownS = 10;
                }
              });
        }
      });
    },

    onSubmit() {
      let self = this;
      this.$v.ga.$touch();
      if (this.$v.ga.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";

        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;

            const postData = {
              name: self.ga.name,
              countryId: self.ga.countryName.value,
              isActive: self.ga.isActive,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.response.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.response.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              industry: self.ga.industry.value,
              subIndustry: self.getIndustries,
              state_id: self.ga.state.value,
              country_id: self.ga.country.value,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.response.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.ga.country = "";
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.response.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
